import { icons } from 'src/assets/icons-new';

export const Constants = {
  specialChars: ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '='],
  splChars: [
    '_',
    '+',
    '{',
    '}',
    ':',
    '"',
    '<',
    '>',
    '?',
    '`',
    '~',
    '[',
    ']',
    ';',
    '"',
    ',',
    '/',
  ],
  asd: ['[', ']', ';', '"', ',', '/'],
  numPadChars: ['-', '+', '/', '*', '.'],
  customChars: ['-', '.', ' '],
  buttons: [
    'Delete',
    'Backspace',
    'Tab',
    'Shift',
    'Capslock',
    'Alt',
    'Ctrl',
    'ArrowRight',
    'ArrowLeft',
  ],
  numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  copyPaste: ['c', 'v', 'x', 'C', 'V', 'X'],
  alphabet: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ],
  cardShadow: {
    morning: 'bg-morning',
    afternoon: 'bg-afternoon',
    evening: 'bg-evening',
  },
  export: {
    csv: 'CSV',
    excel: 'Excel',
  },
  approve: 'Approve',
  deny: 'Deny',
  erroMsgs: {
    strtDateErr: 'Enter valid start date.',
    minStrtDateErr: 'Start date cannot be before January 1, 1900.',
    endDateErr: 'Enter valid end date.',
    minEndDateErr: 'End date cannot be before January 1, 1900.',
    modifiedStrtDateErr: 'Enter valid modify start date.',
    minModifiedStrtDateErr:
      'Modify start date cannot be before January 1, 1900.',
    modifiedEndDateErr: 'Enter valid modify end date.',
    minModifiedEndDateErr: 'Modify end date cannot be before January 1, 1900.',
    lastLoginStrtDateErr: 'Enter valid last login start date.',
    minLastLoginStrtDateErr:
      'Last login start date cannot be before January 1, 1900.',
    lastLoginEndDateErr: 'Enter valid last login end date.',
    minLastLoginEndDateErr:
      'Last login end date cannot be before January 1, 1900.',
    campaignFilterfromDateErr: 'Enter valid Campaign Creation (From) date',
    campaignFilterToDateErr: 'Enter valid Campaign Creation (To) date',
  },
  userData: {
    firstName: 'firstName',
    middleName: 'middleName',
    lastName: 'lastName',
    npn: 'npn',
    title: 'title',
    role: 'role',
    type: 'type',
    division: 'division',
    department: 'department',
    region: 'region',
    email: 'email',
    work: 'work',
    mobile: 'mobile',
    home: 'home',
    fax: 'fax',
    extension: 'extension',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    city: 'city',
    state: 'state',
    zipCode: 'zipCode',
    mapCheck: 'mapCheck',
  },
  fflBu: 'FFL',
  fflBusinessUnitIdIdentity: 1,
  ExcludeBusinessUnits: [9, 999998],
  buSelectionLimit: 10,
  organizationOnCampaign: 'organizationOnCampaign',
  completedRealTimeSteps: 'completedRealTimeSteps',
  realTimeSteps: [
    {
      label: 'Set Campaign Details',
      step: 1,
    },
    {
      label: 'Choose Geography',
      step: 2,
    },
    {
      label: 'Configure Lead Volume',
      step: 3,
    },
    {
      label: 'Campaign Summary',
      step: 4,
    },
  ],
  stateFeatureIdPrefix: '04',
  countyFeatureIdPrefix: '05',
};

export const getUserProfileInfo = () => {
  const userDetails = JSON.parse(localStorage.getItem('roper'));
  return userDetails;
};

export function flattenObject(obj) {
  const result = {};

  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') Object.assign(result, val);
    else result[key] = val;
  });

  return result;
}

export const leadTypes = {
  life: 2,
  health: 1,
  recruitment: 3,
};

export const campaignTabs = {
  realTime: 1,
  customAd: 2,
  buyPerLead: 3,
  pendingApproval: 4,
};

export const realTimeCampaignSteps = {
  1: 'Set Campaign Details',
  2: 'Choose Geography',
  3: 'Configure Lead Volume',
  4: 'Campaign Summary',
};

export const adCampaignSteps = {
  1: 'Select Creative',
  2: 'Campaign Demographics',
  3: 'Set Campaign Details',
  4: 'Campaign Summary',
};

export const bplCampaignSteps = {
  1: 'Set Campaign Details',
  2: 'Choose Geography',
  3: 'Campaign Summary',
};

export const userProfileTabs = {
  accountSettings: 1,
  agentWallet: 2,
  transactionHistory: 3,
};

export const filterAssignedOptions = [
  {
    value: 'ATM',
    label: 'Assigned to Me',
  },
  {
    value: 'ABM',
    label: 'Assigned by Me',
  },
  {
    value: 'UNA',
    label: 'Unassigned',
  },
];

export const planEnrollLeadTypes = [2103, 2153];

export const hiddenLeadStatusesForViewLeads = [500];

export const hiddenLeadStatusesForAdmins = [901];

export const hiddenLeadStatusesForAgents = [900, 901];

export const leadStatusColors = {
  status100: '#95E5FF',
  status200: '#89D5F1',
  status201: '#7CC5E3',
  status202: '#70B5D4',
  status203: '#63A4C6',
  status204: '#5794B8',
  status205: '#4B84AA',
  status300: '#3E749B',
  status301: '#5794B8',
  status302: '#FF4444',
  status400: '#4B84AA',
  status401: '#3E749B',
  status402: '#32648D',
  status403: '#B2CCDB',
  status404: '#494949',
  status500: '#7FD7B2',
  status501: '#194371',
  status502: '#ff4444',
  status503: '#ffd390',
  status504: '#B2CCDB',
  status900: '#494949',
  status901: '#0052CE',
  status902: '#000000',
  status1000: '#717171',
  status10000: '#aaaaaa',
};

export const transactionType = {
  realtimeCampaign: 'Realtime Campaign',
  adCampaign: 'Ad Campaign',
  cashReload: 'Cash Reload',
  leadOrder: 'Lead Order',
  automatedOrder: 'Lead Order (Automated)',
  refund: 'Refund',
  voucher: 'Voucher',
  bplCampaign: 'Buy Per Lead Campaign',
};

export const transactionTypeIdIdentities = {
  cashReloadReferral: 800,
  cashReload: 300,
};

export const training = {
  planEnroll: 1,
  walMart: 2,
};

export const campaignStatus = {
  statusActive: '#5AD76B',
  statusInactive: '#AAAAAA',
};

export const customAd = {
  creativeNames: 'CUSTOM_AD_CREATIVE_NAMES',
  campaignTypes: 'CUSTOM_AD_CAMPAIGN_TYPES',
};

export const features = {
  CanViewUser: 10002,
  CanUpdateUser: 10003,
  UserAdmin: 10019,
  CanViewNotification: 10042,
  CanViewUserProfileAll: 10072,
  Leads: 20000,
  CanCreateLead: 20001,
  CanViewLead: 20002,
  CanUpdateLead: 20003,
  CanViewLeadAll: 20012,
  CanManageLeadRefund: 20018,
  OrderLeads: 20020,
  RealTimeLeads: 20040,
  AdCampaigns: 20060,
  VendorCredits: 20080,
  Coupons: 70000,
  CanViewCoupon: 70002,
  CanCreateCouponAll: 70011,
  CanViewCouponAll: 70012,
  CanUpdateCouponAll: 70013,
  AskASpecialist: 70020,
  CanCreateEGift: 70041,
  CanViewEGift: 70042,
  EGifts: 70040,
  FAQ: 70060,
  Banners: 70080,
  Referrals: 70100,
  WelcomeModal: 70120,
};

export const customAdLoaderText = `Please be patient while we launch your ad campaign. This may take several minutes while we optimize your campaign settings.
  Do not close this window or refresh the page until you see a confirmation message. Thank you for choosing LeadCENTER.`;

export const defaultAdCampaign = {
  activeStep: 1,
  activeTab: 2,
  completedSteps: [],
  steps: [
    {
      step: 1,
      completed: false,
      label: 'Select Creative',
    },
    {
      step: 2,
      completed: false,
      label: 'Campaign Demographics',
    },

    {
      step: 3,
      completed: false,
      label: 'Set Campaign Details',
    },
    {
      step: 4,
      completed: false,
      label: 'Campaign Summary',
    },
  ],
  productCategoryIdIdentity: 2,
  campaignType: {
    adCampaignTypeId: 'bfa671dd-456f-478e-be1a-d1644040421c',
    adCampaignTypeIdIdentity: 1,
    isInactive: false,
    name: 'Direct Mail',
    displayValue: 'Direct Mail',
    description: 'Direct Mail',
    sortOrder: 10,
    label: 'Direct Mail',
    value: 1,
  },
  creativeType: { label: 'All', value: '*' },
  creative: null,
  isStatesAndCountiesGeographySelected: true,
  selectedLocations: [],
  estimatedReach: null,
  ageRange: {},
  ageRangeMin: null,
  ageRangeMax: null,
  incomeRange: {},
  birthMonths: [],
  campaignName: '',
  numberOfPieces: 1000,
  orderTotal: 0,
  discountAmount: 0,
  eGiftAmount: 0,
  userCreditAmount: 0,
  serviceFee: 0,
  orderSubtotal: 0,
  disabledReasonText: '',
};

export const healthCampaigns = ['Test']; // just for testing

export const DEFAULT_INCOME_RANGE = [0, 20];
export const HOUSEHOLD_INCOME_TYPE = 'Household Income';
export const AGE_RANGE_TYPE = 'Age Range';

export const deleteCampaignConfirmationText =
  'Are you sure you want to cancel all progress and delete this campaign? All current campaign settings will be lost.';

export const cancellationReasonOther = {
  name: 'Other',
};

export const buyPerLeadCampaignStatusActive = {
  buyPerLeadCampaignStatusIdIdentity: 10,
};

export const buyPerLeadCampaignStatusCanceled = {
  buyPerLeadCampaignStatusIdIdentity: 30,
};

export const buyPerLeadCampaignStatusDenied = {
  buyPerLeadCampaignStatusIdIdentity: 40,
};

export const faqSection = {
  titles: {
    supportAndGuides: 'supportAndGuides',
    registeringForAnAccount: 'registeringForAnAccount',
    personalInformation: 'personalInformation',
    marketPlaces: 'marketPlaces',
    leadsPricing: 'leadsPricing',
    realtimeCamapaigns: 'realtimeCamapaigns',
    refundRequests: 'refundRequests',
    managingLeads: 'managingLeads',
    thirdPartyIntegrations: 'thirdPartyIntegrations',
  },
  thirdPartyIntegrationsSubSections: {
    phoneBurner: 'phoneBurner',
    phoneBurnerMoreInformation: 'phoneBurnerMoreInformation',
  },
};

export const supportEmail = 'support@leads.integrity.com';

export const thirdPartyApps = {
  phoneBurner: 1,
};

export const automatedOrderStatus = {
  active: 'Active',
  paused: 'Paused',
  canceled: 'Canceled',
};

export const campaignStatusIdIdentities = {
  activeUserCampaignStatusIdIdentity: 1,
  inactiveUserCampaignStatusIdIdentity: 2,
  pendingCanellationUserCampaignStatusIdIdentity: 3,
};

export const leadTypeNames = {
  life: 'Life',
  health: 'Health',
  recruitment: 'Recruitment',
};

export const userStatuses = {
  active: 1,
  inactive: 2,
  blocked: 3,
};

export const pageType = {
  view: 'view',
  edit: 'edit',
};

export const productCategoryIdIdentities = {
  health: 1,
  life: 2,
  recruitment: 3,
  miscellaneous: 9,
};

export const voucherStatuses = {
  new: 1,
  active: 2,
  spent: 4,
  revoked: 8,
  expired: 9,
};

export const pageNames = {
  vendorCredits: 'vendorCredits',
  manageAgents: 'manageAgents',
};

export const requestStatuses = {
  pending: 2,
  submitted: 3,
  approved: 4,
  denied: 5,
};

export const creditRequestStatusIdIdentities = {
  notRequested: 10,
  requested: 20,
  approved: 50,
  denied: 90,
};

export const clientsUrl = `${process.env.REACT_APP_MEDICARE_CENTER_URL}/clientsSSO`;

export const tabOptions = {
  manageLeads: [
    {
      id: 2,
      name: 'life',
      displayValue: 'Life',
      icon: icons.lifeIcon,
    },
    {
      id: 1,
      name: 'health',
      displayValue: 'Health',
      icon: icons.healthIcon,
    },
    {
      id: 3,
      name: 'recruitment',
      displayValue: 'Recruitment',
      icon: icons.recruitmentIcon,
    },
  ],
  egiftTabIds: {
    activated: 1,
    archived: 2,
  },
  status: [
    {
      id: 1,
      name: 'activated',
      displayValue: 'Activated',
      icon: icons.activeLight,
    },
    {
      id: 2,
      name: 'Archived',
      displayValue: 'Archived',
      icon: icons.archivedLight,
    },
  ],
  agentTabs: [
    {
      id: 1,
      name: 'agents',
      displayValue: 'Agents',
      icon: icons.usersLight,
      disabled: false,
      isApproved: true,
    },
    {
      id: 2,
      name: 'pending',
      displayValue: 'Pending Agents',
      icon: icons.userCheck,
      disabled: true,
      isApproved: false,
    },
  ],
};
export const daysForDate = {
  thirty: 30,
  ninety: 90,
};

export const leadTypesToExclude = [1, 2, 4];

export const medicareLink = '/medicareRedirect';

export const bannerActionLinks = {
  automateAndSave: 'automateAndSave',
  miscellaneousOrder: 'miscellaneousOrder',
};

export const adCampaignTypeIdIdentities = {
  directMail: 1,
};

export const importTypeLeadIdIdentities = {
  life: 950,
  health: 951,
  recruitment: 952,
};

export const leadTypeIdIdentities = {
  nonLicensedFFLRecruitmentLead: 5100,
  licensedAgentFFLLead: 5101,
  indexedUniversalLifeIULFacebook: 2222,
};

export const genericErrorMessage = 'Something went wrong.';

export const leadGroupTypeIdIdentities = {
  internet: 4,
};
export const supportPhoneNumber = '8009604196';

export const allLeadPartnersOption = {
  label: 'All Lead Partners',
  value: '*',
};

export const leadStatusIdIdentitites = {
  creditRequested: 901,
  sold: 500,
  declined: 502,
  lapsed: 504,
  dormant: 900,
  archive: 1000,
  deleted: 10000,
};

export const dropdownMaster = {
  agentLicenseTypes: '25d24a9e-19d5-41cd-a1ed-8235c48ef07d',
  numberOfCarrierAppointments: '6ff78532-485a-4325-a914-baf177573e29',
  numberOfStateLicenses: 'cd101672-1335-45a6-bd09-12f741547825',
  agentSpecialties: 'd3a5051f-c20b-4ca2-9f66-217ad4bef659',
};

export const genderOptions = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
];

export const leadPartnerLeadTypeIdIdentities = {
  integrityPreferred: 1,
};

export const tasks = {
  reminder: 'Reminder',
};

export const leadStatusArrayWithNoCreditRequest = [
  leadStatusIdIdentitites.sold,
  leadStatusIdIdentitites.declined,
  leadStatusIdIdentitites.lapsed,
  leadStatusIdIdentitites.dormant,
  leadStatusIdIdentitites.archive,
  leadStatusIdIdentitites.deleted,
];

export const layoutWidths = {
  drawerWidth: 280,
  collapsedWidth: 60,
};

export const toasterSeverity = {
  success: 0,
  info: 10,
  warning: 90,
  error: 100,
};
