export const themeVariables = {
  colorInvalid: '#cf4843',
  colorTitle: '#7f7f8a',
  subtitleColor: '#828ea1',
  labelColor: '#bbbbbb',
  focusColor: '#ffff00',
  green: '#0fba00',
  demoBorderColor: '#ff0dff',
  lightBlue: '#f4f8fb',
  warningBg: '#1f315f',

  // State of the input colors
  buttonDisabledColor: '#a0c4f8',

  // Border colors
  invalidBorder: '#d32028',

  // Border radius
  borderRadius: '8px',

  // Font sizes
  fs12: '12px',
  fs14: '14px',
  fs16: '16px',
  fs18: '18px',
  fs20: '20px',
  fs24: '24px',
  fs48: '48px',

  // Theme colors used for Importer
  borderLight: '#c7ccd1',
  mainTextColor: '#333333',
  mutedTextColor: '#8f99a3',
  pageBgHover: '#dee1e8',
  brandTextColor: '#236c9e',
  brandTextHoverColor: '#0079cc',
  brandBgColor: '#236c9e',
  brandBgHoverColor: '#0079cc',
  disabledColor: '#a4aec1',
  disabledContrastColor: '#e8eaef',
  errorConfirmStateColor: '#f0514c',
  errorStateNotificationColor: '#cf4843',
  errorConfirmStateBackground: '#fff1f0',
  importTextColor: '#3c4b64',
  shadedColor: '#f5f6fa',

  // Breakpoints
  breakpointXxsm: '380px',
  breakpointXsm: '450px',
  breakpointSm: '576px',
  breakpointMd: '768px',
  breakpointLg: '992px',
  breakpointXl: '1200px',
  breakpointXxl: '1400px',
  breakpointLargeres: '1600px',
  breakpointHighres: '1900px',

  // Lead status colors
  statusNew: '#95b3ff',
  statusFirstCall: '#89a6f1',
  statusSecondCall: '#7c99e3',
  statusThirdCall: '#708cd4',
  statusVicemail: '#637fc6',
  statusText: '#5772b8',
  statusDoorKnock: '#4b66aa',
  statusAppointment: '#3e589b',
  statusSubmitted: '#324c8d',
  status501: '#194371',
  status504: '#b2ccdb',
  status900: 'purple',
  status901: 'pink',

  // Coupon status colors
  filterColunm: 'rgba(0, 158, 21, 0.5)',
  componentsChipDefaultEnabledBorder: '#bdbdbd',

  // Referenced design system colors (version-1)
  primaryAction: '#0052ce',
  primaryActionOnhover: '#2175f4',
  primaryActionDisabled: '#a0c4f8',
  backgroundPill: '#d8e6f9',
  backgroundBody: '#f1faff',
  headerFont: '#002d72',
  backgroundHeaderNav: '#051d43',
  primaryFont: '#434a51',
  secondaryFont: '#000000',
  tertiaryFont: '#717171',
  primaryFontInactive: '#aaaaaa',
  borderLines: '#dfdedd',
  rowHighlight: '#ededee',
  backgroundError: '#fff1f1',
  backgroundModal: '#f9fafb',
  backgroundWhite: '#ffffff',
  iconSelectionColor: '#f4f6fa',
  success500: '#7fd7b2',
  warning500: '#ffd390',
  error500: '#ff4444',
  error200: '#ffdcdc',
  success200: '#d8f5d9',
  warning200: '#fff2dd',
  error300: '#ff7676',
  gradientMorning2: '#72d3e8',
  gradientAfternoon2: '#76a7de',
  gradientEvening2: '#5b3dce',
  gradientSunset1: '#fdfafa',
  gradientSunset2: '#ff483d',
  diamond: '#4f98be',
  titanium: '#757575',
  platinum: '#898b8d',
  leadCENTERGold: '#a99057',
  silver: '#b9b7bd',
  bronze: '#8c5846',
  leadOrder: '#a8cdf6',
  voucher: '#bb77ee',
  refund: '#c1c1c1',
  cashReload: '#5ad76b',
  realtimeCampaign: '#0267ff',
  adCampaign: '#ffde00',
  automatedOrder: '#8893f6',
  carouselDot: '#d9d9d9',
  sectionBlue: '#f2f6fd',
  gradientMorning: '#6a95ff',
  gradientAfternoon: '#4178ff',
  primaryMain: '#4178ff',
  gradientEvening: '#1457ff',
  noteWarningFont: '#e28a05',
  titleBlue: '#052a63',
  successMain: '#009e15',
  errorMain: '#c81e27',
  textDisabled: '#9e9e9e',
  backgroundWarning: '#fcf2e8',
  backgroundDefault: '#f1f1f1',
  warningMain: '#f56600',
  integrityNavy: '#052a63',
  integrityGrayLight1: '#f1f1f1',
  integrityGrayLight2: '#dddddd',
  infoMain: '#49648b',
  componentsInputOutlinedEnabledBorder: 'rgba(0, 0, 0, 0.23)',
  dropShadow: 'rgba(0, 0, 0, 0.161)',
  componentsAlertSuccessBackground: '#e5faeb',
  componentsAlertInfoBackground: '#f1faff',
  componentsAlertErrorBackground: '#fbdede',
  newBlue: '#23418e',
  darkBlue: '#525252',
  componentsRatingActiveFill: '#ffb400',
  divider: '#cccccc',
  actionActive: 'rgba(0, 0, 0, 0.56)',
};
