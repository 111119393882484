import { createTheme } from '@mui/material/styles';
import { themeVariables } from './scss/variables';

const theme = createTheme({
  palette: {
    primary: {
      main: themeVariables.primaryMain,
    },
    secondary: {
      main: themeVariables.secondaryFont,
    },
  },
  typography: {
    fontFamily: 'Lato',
    fontSize: '1rem',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: { fontSize: '1.25rem' },
    h5: { fontSize: '1rem' },
    h6: { fontSize: '0.875rem' },
  },
});

export default theme;
